<template>
  <header class="header">
    <a :href="toolsUrl">
      <img class="logo" src="~/assets/img/vl-logo7.png" alt="VL" height="50" width="50" />
    </a>
    <h1>Vertical Leap Tools</h1>
    <div v-if="user" class="user-container">
      <span class="user-name-email">{{ nameOrEmail }}</span>
      <div class="dropdown">
        <img :src="avatarUrl" :alt="nameOrEmail" height="50" width="50" class="avatar" />
        <div class="dropdown-content">
          <p @click="SignOut">Sign Out</p>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const config = useRuntimeConfig();
const toolsUrl = config.public.toolsUrl;
import gravatarUrl from 'gravatar-url';
import { AuthedUser } from "~/lib/user";
import { removeCookie } from "typescript-cookie";

function SignOut(event: any) {
  if (event.currentTarget === event.target) {
    removeCookie("VLAuthorization", { domain: config.public.domain });
    location.reload(); // Reloads the current page
  }
}

const nuxtApp = useNuxtApp();
const user: AuthedUser | null = await nuxtApp.$user();

const avatarUrl = user ? gravatarUrl(user.email, { size: 50, default: "mm", rating: "g" }) : "";
const nameOrEmail = user ? (user.name ?? user.email) : "";
</script>


<style scoped>
.avatar {
  border: 2px solid var(--vl-mid-blue);
  border-radius: 50%;
}

.header {
  align-items: center;
  background-color: var(--vl-dark-blue);
  box-shadow: 0 1px 8px var(--dark-grey);
  display: grid;
  gap: 1rem;
  grid-template-columns: 50px auto max-content;
  height: 3rem;
  padding: 1rem;
  position: sticky;
  top: 0;
  width: calc(100% - 2rem);
}

.header h1 {
  color: white;
  margin: 0;
}

.logo {
  position: relative;
  top: -7px;
}

.user-container {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: max-content max-content;
}

.user-name-email {
  color: white;
  font-size: smaller;
}

@media screen and (max-width: 600px) {
  .user-container {
    grid-template-columns: max-content;
  }

  .user-name-email {
    display: none;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--vl-dark-blue);
  color: #fff;
  z-index: 1;
  text-align: center;
  min-width: 100px;
  right: 0px;
  border: 2px solid var(--vl-mid-blue);
  border-radius: 10px;
}

.dropdown:hover .dropdown-content {
  display: block;
  margin-right: auto;
}

.dropdown-content:hover {
  background-color: #fff;
  color: var(--vl-dark-blue);
  cursor: pointer;
}
</style>