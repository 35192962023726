<template>
  <div v-if="state.isUserVerticalLeapStaff">
    <slot></slot>
  </div>
  <div v-else>
    <div class="dx-card dx-card-shadow dx-card-padding">
      <div class="dx-card-header">
        <div class="dx-card-header-content text-center">
          <h3 class="dx-card-title">Sign-in required</h3>
        </div>
      </div>
      <div class="dx-card-body">
        <div class="text-center">
          <div v-if="state.isSignedIn">
            <p>
              You need to sign-in as another user to use these tools.
              <a :href="signInUrl">Sign-in to start</a>
            </p>
          </div>
          <div v-else>
            <p>
              You need to be signed-in to use these tools.
              <a :href="signInUrl">Sign-in to start</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script setup lang="ts">
import { useInterval } from "@vueuse/core";

async function isUserVerticalLeapStaff(): Promise<boolean> {
  const nuxtApp = useNuxtApp();
  const user = await nuxtApp.$user();
  return user?.isVerticalLeapStaff ? true : false;
}

async function isUserSignedIn(): Promise<boolean> {
  const nuxtApp = useNuxtApp();
  const user = await nuxtApp.$user();
  return user ? true : false;
}

const state = reactive({
  isUserVerticalLeapStaff: await isUserVerticalLeapStaff(),
  isSignedIn: await isUserSignedIn()
});

const { counter, pause, resume } = useInterval(5000, { controls: true });

const requestUrl = useRequestURL();
const config = useRuntimeConfig();
const signInUrl = config.public.signInUrl + "?returnUrl=" + requestUrl.href;

watch(counter, async () => {
  pause();

  state.isUserVerticalLeapStaff = await isUserVerticalLeapStaff();
  state.isSignedIn = await isUserSignedIn();

  resume();
});
</script>
