import { importSPKI, type JWTPayload, jwtVerify } from "jose";

export async function getUser(
  vlAccessToken?: string
): Promise<AuthedUser | null> {
  if (!vlAccessToken) return null;

  try {
    const runtimeConfig = useRuntimeConfig();
    const publicKey = await importSPKI(
      runtimeConfig.public.jwtVerificationKeyPem,
      "RS256"
    );
    const { payload } = await jwtVerify(vlAccessToken, publicKey);
    return new AuthedUser(payload, vlAccessToken);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export class AuthedUser {
  constructor(jwtPayload: JWTPayload, vlAccessToken: string) {
    this.accessToken = vlAccessToken;
    this.email = (jwtPayload["email"] as string) ?? "";

    const roles = (jwtPayload["roles"] as string[]) ?? [];

    this.isVerticalLeapStaff = roles.includes("VerticalLeapStaff") ?? false;
    this.isSideshowStaff =
      (roles.includes("ReddicoStaff") || roles.includes("VerticalLeapStaff")) ??
      false;
    this.name = jwtPayload["name"] as string | null;
  }

  accessToken: string;
  email: string;
  isVerticalLeapStaff: boolean;
  isSideshowStaff: boolean;
  name?: string | null;
}
