<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: "dx-viewport",
  },
});

import LogRocket from "logrocket";

const logrocketAppId = useRuntimeConfig().public.logRocketAppId;

if (logrocketAppId) {
  LogRocket.init(logrocketAppId, { rootHostname: "vertical-leap.tools" });

  const nuxtApp = useNuxtApp();
  const user = await nuxtApp.$user();

  if (user)
    LogRocket.identify(user.email, {
      name: user.name ?? "",
      email: user.email
    });
}
</script>

<template>
  <div>
    <NuxtLayout>
      <AppHeader />
      <AuthEnforcer>
        <NuxtPage />
      </AuthEnforcer>
    </NuxtLayout>
  </div>
</template>
