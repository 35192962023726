import { getUser } from "../lib/user";
import { Cookies } from "typescript-cookie";

export default defineNuxtPlugin(() => {
  const user = async () => {
    const vlAuthorizationCookie = Cookies.get("VLAuthorization");
    if (!vlAuthorizationCookie) return null;
    const user = await getUser(vlAuthorizationCookie as string);
    return user;
  };

  return {
    provide: {
      user,
    },
  };
});
