import { default as _91url_93kcgPnxE03zMeta } from "/src/pages/content/calendar/[url].vue?macro=true";
import { default as indexUeFAQqS7ejMeta } from "/src/pages/content/index.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as _91smartGoalId_939PmFgnTNmVMeta } from "/src/pages/projects/[projectId]/goals/[smartGoalId].vue?macro=true";
import { default as newaJX9haiCF9Meta } from "/src/pages/projects/[projectId]/goals/new.vue?macro=true";
import { default as indexIL6GTCklH5Meta } from "/src/pages/projects/[projectId]/index.vue?macro=true";
import { default as _91userStoryId_93mw3aVkP52sMeta } from "/src/pages/projects/[projectId]/user-stories/[userStoryId].vue?macro=true";
import { default as indexJ39cZJ81HOMeta } from "/src/pages/projects/index.vue?macro=true";
export default [
  {
    name: "content-calendar-url",
    path: "/content/calendar/:url()",
    component: () => import("/src/pages/content/calendar/[url].vue").then(m => m.default || m)
  },
  {
    name: "content",
    path: "/content",
    component: () => import("/src/pages/content/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexvsa0KwJWqiMeta || {},
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-goals-smartGoalId",
    path: "/projects/:projectId()/goals/:smartGoalId()",
    meta: _91smartGoalId_939PmFgnTNmVMeta || {},
    component: () => import("/src/pages/projects/[projectId]/goals/[smartGoalId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-goals-new",
    path: "/projects/:projectId()/goals/new",
    meta: newaJX9haiCF9Meta || {},
    component: () => import("/src/pages/projects/[projectId]/goals/new.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId",
    path: "/projects/:projectId()",
    meta: indexIL6GTCklH5Meta || {},
    component: () => import("/src/pages/projects/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-user-stories-userStoryId",
    path: "/projects/:projectId()/user-stories/:userStoryId()",
    meta: _91userStoryId_93mw3aVkP52sMeta || {},
    component: () => import("/src/pages/projects/[projectId]/user-stories/[userStoryId].vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/src/pages/projects/index.vue").then(m => m.default || m)
  }
]